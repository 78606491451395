import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from '@env/environment';
import { Auth, AuthenticationResponse, QrCodeResponse } from '@models/auth';
import { InterceptorSkipHeader } from './auth-interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly _http: HttpClient) {}

  login(auth: Auth): Observable<AuthenticationResponse> {
    return this._http.post<AuthenticationResponse>(
      `${environment.api}/auth/login`,
      auth,
      {
        headers: new HttpHeaders().set(InterceptorSkipHeader, ''),
      }
    );
  }

  logout(): Observable<any> {
    return this._http.post<any>(`${environment.api}/auth/logout`, {});
  }

  isAuthenticated(): Observable<any> {
    return this._http
      .get<any>(`${environment.api}/validateToken`)
      .pipe(map((res) => res.success));
  }

  generateQrCode(token: string): Observable<QrCodeResponse> {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`)
      .set(InterceptorSkipHeader, '');
    return this._http.get<QrCodeResponse>(
      `${environment.api}/auth/two-factor-authentication/qrcode`,
      { headers }
    );
  }

  public enable(token: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    return this._http.post<any>(
      `${environment.api}/auth/two-factor-authentication/enable`,
      {},
      { headers: headers }
    );
  }

  public authenticationConfirm(code: string, token: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    return this._http.post<any>(
      `${environment.api}/auth/two-factor-authentication/confirm`,
      { code },
      { headers: headers }
    );
  }
}
